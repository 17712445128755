import moment from 'moment'
moment.locale('es')
export default {
  methods: {
    formatDate (date, format = 'DD MMMM YY') {
      return !date ? 'Sin fecha' : moment(date).format(format)
    },
    formatDateISO (date) {
      const newDate = moment(date).format('YYYY-MM-DD')
      return newDate
    }
  }
}
