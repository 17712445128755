<template>
  <div class="columns is-multiline is-vcentered">
    <div class="column is-12">
      <h3 class="subtitle has-text-weight-bold">
        Servicios
      </h3>
    </div>
    <div class="column is-8">
      <div class="columns is-multiline is-vcentered">
      <!-- Buscador -->
      <div class="column is-5">
        <div class="field">
          <div class="control has-icons-right">
            <input
              class="input is-rounded input_ibs"
              type="text"
              placeholder="Buscar"
              v-model="searchText"
              @input="searchUser"
            >
            <span class="icon is-right">
              <img class="icon_search" src="@/assets/icon/search.png">
            </span>
          </div>
        </div>
      </div>
      <!-- Select -->
      <div class="column is-4">
        <v-select
          class="input_select"
          label="name"
          placeholder='Tipo de Servicio'
          v-model="statusSelected"
          :reduce="status => status.id"
          :options="serviceNameList"
          @input="setStatus"
        >
        </v-select>
      </div>
    </div>
    </div>
    <div class="column is-12">
      <base-table
        class="is-striped-2 is-fullwidth is-hoverable"
        :columns="columnsData"
        :data="serviceList"
        :loading="loading"
      >
        <div
          slot="config"
          slot-scope="scope"
        >
          <div class="is_btn_status">
            {{ scope.row.status}}
          </div>
        </div>
      </base-table>
    </div>
    <div class="column is-12">
      <div class="columns is-multiline is-vcentered">
        <div class="column is-12">
          <base-pagination
            :page="page"
            :pages="parseInt(pages)"
            :action="paginator"
            :loading="loading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import mixins from '../../mixins/formats'
import service from '@/constants/catalogs/services'
export default {
  mixins: [mixins],
  name: 'CustomerDetailService',
  components: {
    BaseTable: () => import('@/components/Table.vue'),
    BasePagination: () => import('@/components/BasePagination.vue')
  },
  data () {
    return {
      customerId: this.$route.params.customerId,
      page: 1,
      pages: 1,
      loading: false,
      searchText: '',
      // serviceList: [],
      statusSelected: '',
      columnsData: [
        {
          id: 'reference',
          header: 'Referencia Interna',
          class: 'column-mobile',
          accessor: (row) => `${row.reference}`,
          link: true,
          type: 'link',
          route: (row) => ({ name: 'detalle-servicio', params: { serviceId: row.id, customerId: row.customer_id } })
        },
        { id: 'serviceName', header: 'Servicio', class: 'is-hidden-mobile' },
        { id: 'inspection_date', header: 'Fecha de Inspección', class: 'column-mobile is-hidden-mobile', accessor: (row) => this.formatDate(row.inspection_date, 'DD MMMM YYYY') },
        { id: 'container_number', header: 'No. Contenedor', class: 'column-mobile' },
        { id: 'po_number', header: 'PO', class: 'column-mobile' }
      ],
      serviceNameList: service
    }
  },
  methods: {
    ...mapActions(['getServiceByCustomerId']),
    paginator (page) {
      this.page = page
      this.loading = true
      this.getServiceByCustomerId({ customerId: this.customerId, page: page })
        .then(response => {
          if (response.success) {
            this.serviceList = response.services
            this.pages = response.totalPages
          }
          this.loading = false
        })
    },
    searchUser ({ target }) {
      this.page = 1
      this.loading = true
      if (target.value.length >= 3 || target.value.length === 0) {
        this.searchText = target.value.length === 0 ? '' : target.value
        this.$router.push({ name: 'detalle-cliente', query: { page: this.page, search: target.value, status: this.statusSelected } })
        this.getServiceByCustomerId({ customerId: this.customerId, page: this.page, search: target.value, status: this.statusSelected })
          .then(response => {
            if (response.success) {
              this.serviceList = response.services
              this.pages = response.totalPages
            }
            this.loading = false
          })
      }
    },
    setStatus (status) {
      if (status === null) { status = '' }
      this.statusSelected = status
      this.page = 1
      this.loading = true
      this.$router.push({ name: 'detalle-cliente', query: { page: this.page, search: this.searchText, status: this.statusSelected } })
      this.getServiceByCustomerId({ customerId: this.customerId, page: this.page, search: this.searchText, status: this.statusSelected })
        .then(response => {
          if (response.success) {
            this.serviceList = response.services
            this.pages = response.totalPages
          }
          this.loading = false
        })
    }
  },
  beforeMount () {
    this.loading = true
    this.getServiceByCustomerId({ customerId: this.customerId, page: this.page })
      .then(response => {
        if (response.success) {
          this.serviceList = response.services
          this.pages = response.totalPages
        }
        this.loading = false
      })
  }
}
</script>

<style lang="scss" scoped>

</style>
